import { gql, useQuery } from '@apollo/client';

import { BugTracker } from 'kb-shared/utilities/bugTracker';

export const UNPAID_INVOICES_COUNT = gql`
  query unpaidInvoicesCount {
    unpaidInvoicesCount
  }
`;

interface UnpaidInvoicesCountResponse {
  unpaidInvoicesCount: number;
  loading: boolean;
}

export const useUnpaidInvoicesCount = (): UnpaidInvoicesCountResponse => {
  const { loading, data } = useQuery<UnpaidInvoicesCountResponse>(UNPAID_INVOICES_COUNT, {
    onError: error => BugTracker.notify(error, 'UnpaidInvoicesCount')
  });

  const unpaidInvoicesCount = data?.unpaidInvoicesCount ?? 0;

  return { unpaidInvoicesCount, loading };
};
